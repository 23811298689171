exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-aboutpage-about-landing-jsx": () => import("./../../../src/pages/aboutpage/aboutLanding.jsx" /* webpackChunkName: "component---src-pages-aboutpage-about-landing-jsx" */),
  "component---src-pages-aboutpage-about-our-locations-jsx": () => import("./../../../src/pages/aboutpage/aboutOurLocations.jsx" /* webpackChunkName: "component---src-pages-aboutpage-about-our-locations-jsx" */),
  "component---src-pages-aboutpage-about-team-jsx": () => import("./../../../src/pages/aboutpage/aboutTeam.jsx" /* webpackChunkName: "component---src-pages-aboutpage-about-team-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-contactuspage-contact-us-jsx": () => import("./../../../src/pages/contactuspage/contactUs.jsx" /* webpackChunkName: "component---src-pages-contactuspage-contact-us-jsx" */),
  "component---src-pages-homepage-index-about-us-jsx": () => import("./../../../src/pages/homepage/indexAboutUs.jsx" /* webpackChunkName: "component---src-pages-homepage-index-about-us-jsx" */),
  "component---src-pages-homepage-index-changing-world-jsx": () => import("./../../../src/pages/homepage/indexChangingWorld.jsx" /* webpackChunkName: "component---src-pages-homepage-index-changing-world-jsx" */),
  "component---src-pages-homepage-index-countries-jsx": () => import("./../../../src/pages/homepage/indexCountries.jsx" /* webpackChunkName: "component---src-pages-homepage-index-countries-jsx" */),
  "component---src-pages-homepage-index-landing-jsx": () => import("./../../../src/pages/homepage/indexLanding.jsx" /* webpackChunkName: "component---src-pages-homepage-index-landing-jsx" */),
  "component---src-pages-homepage-index-why-choose-jsx": () => import("./../../../src/pages/homepage/indexWhyChoose.jsx" /* webpackChunkName: "component---src-pages-homepage-index-why-choose-jsx" */),
  "component---src-pages-homepage-linkage-slider-jsx": () => import("./../../../src/pages/homepage/linkageSlider.jsx" /* webpackChunkName: "component---src-pages-homepage-linkage-slider-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-iso-9001-jsx": () => import("./../../../src/pages/ISO-9001.jsx" /* webpackChunkName: "component---src-pages-iso-9001-jsx" */),
  "component---src-pages-isopage-iso-landing-jsx": () => import("./../../../src/pages/isopage/isoLanding.jsx" /* webpackChunkName: "component---src-pages-isopage-iso-landing-jsx" */),
  "component---src-pages-legal-terms-of-sale-jsx": () => import("./../../../src/pages/legal-terms-of-sale.jsx" /* webpackChunkName: "component---src-pages-legal-terms-of-sale-jsx" */),
  "component---src-pages-legaltermsofsale-legal-landing-jsx": () => import("./../../../src/pages/legaltermsofsale/legalLanding.jsx" /* webpackChunkName: "component---src-pages-legaltermsofsale-legal-landing-jsx" */),
  "component---src-pages-news-blogs-jsx": () => import("./../../../src/pages/news/blogs.jsx" /* webpackChunkName: "component---src-pages-news-blogs-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-news-press-releases-jsx": () => import("./../../../src/pages/news/pressReleases.jsx" /* webpackChunkName: "component---src-pages-news-press-releases-jsx" */),
  "component---src-pages-news-published-articles-jsx": () => import("./../../../src/pages/news/publishedArticles.jsx" /* webpackChunkName: "component---src-pages-news-published-articles-jsx" */),
  "component---src-pages-news-sanity-press-releases-slug-current-jsx": () => import("./../../../src/pages/news/{sanityPressReleases.slug__current}.jsx" /* webpackChunkName: "component---src-pages-news-sanity-press-releases-slug-current-jsx" */),
  "component---src-pages-our-process-jsx": () => import("./../../../src/pages/our-process.jsx" /* webpackChunkName: "component---src-pages-our-process-jsx" */),
  "component---src-pages-processpage-process-landing-jsx": () => import("./../../../src/pages/processpage/processLanding.jsx" /* webpackChunkName: "component---src-pages-processpage-process-landing-jsx" */),
  "component---src-pages-processpage-process-supplier-jsx": () => import("./../../../src/pages/processpage/processSupplier.jsx" /* webpackChunkName: "component---src-pages-processpage-process-supplier-jsx" */),
  "component---src-pages-supplier-terms-and-conditions-jsx": () => import("./../../../src/pages/supplier-terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-supplier-terms-and-conditions-jsx" */),
  "component---src-pages-supplierterms-supplier-landing-jsx": () => import("./../../../src/pages/supplierterms/supplierLanding.jsx" /* webpackChunkName: "component---src-pages-supplierterms-supplier-landing-jsx" */),
  "component---src-pages-sustainability-jsx": () => import("./../../../src/pages/sustainability.jsx" /* webpackChunkName: "component---src-pages-sustainability-jsx" */),
  "component---src-pages-technology-jsx": () => import("./../../../src/pages/technology.jsx" /* webpackChunkName: "component---src-pages-technology-jsx" */),
  "component---src-pages-technology-technology-landing-jsx": () => import("./../../../src/pages/technology/technologyLanding.jsx" /* webpackChunkName: "component---src-pages-technology-technology-landing-jsx" */)
}

